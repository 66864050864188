/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.ng2-tag-input{
    border-bottom: 0 !important;
    padding: 0 !important;
    tag-input-form{width: 100%;}
    input{
        display: block !important;
        width: 100% !important;
        border: 1px solid #e8e8f7 !important;
        border-radius: 5px !important;
        padding: 0.375rem 0.75rem !important;
    }
}

.__fixPicker{
    ngx-daterangepicker-material .md-drppicker{
        flex-direction: column;
        .calendar-time{text-align: left; padding: 0 10px;}
        .buttons{margin-top: -42px;}
    }
}

.__instructions{
    height: fit-content;
    margin: auto;
    text-align: center;
    padding: 50px 20px;
    opacity: 0.7;

    .fa{color: var(--dark);font-size: 6em; opacity: 0.4; margin-bottom: 50px;}
    h2{color: var(--primary); font-weight: 300;}
    p{font-size: 1.2em; margin: 20px 0 0;}
}

.swal2-confirm{
    background-color: var(--success) !important;
}

.__band{
    .opacity{opacity: 0.3;}
    &::after{
        content: attr(band-label);
        display: block;
        font-weight: 500;
        width: 130%;
        height: auto;
        padding: 8px 0;
        font-size: .95em;
        line-height: 1;
        color: white;
        box-shadow: 0px 4px 10px -2px #000;
        z-index: 2;
        text-align: center;
        background: linear-gradient(45deg, #a83bd6, #2e00d8);
        position: absolute;
        top: 25px;
        left: 25%;
        transform: rotate(45deg);
        transform-origin: center;
    }
    &.warning::after{background: linear-gradient(45deg, #d6763b, #da0d73);}
}