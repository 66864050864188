/* Importing node modules SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "~simple-datatables/dist/style.css";
@import "~leaflet/dist/leaflet.css";
@import "~chartist/dist/scss/chartist.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-archwizard/archwizard.css';
@import '~animate.css/animate.min.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~angular-calendar/scss/angular-calendar.scss";
@import "~flatpickr/dist/flatpickr.css";
@import '~highlight.js/scss/solarized-dark.scss';

//custom scss
@import "scss/style.scss";
// @import "css/skins.scss";
// @import "css/dark-style.scss";
// @import "css/colors/default.scss";
@import "assets/css/icon-list.css";
// @import "css/colors/color.scss";

@import "css/sidemenu/sidemenu.scss";

.pac-container {
    z-index: 2000;
    display: inline-block;
}
.md-drppicker{
    display: flex;
    top: 55px !important;
    
    &.double{left: unset !important; right: 0 !important;}
}

.btn i.typcn.typcn-eye {
    line-height: 12px;
    font-size: 1.1em;
    left: -1px;
    position: relative;
}

app-sidemenu{
    .ps__rail-x, .ps__rail-y{display: none !important;}
}


.main-content{
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 90vh;
        background-image: url(../assets/img/pattern.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        right: 0;bottom: 0;
        opacity: 0.2;
        pointer-events: none;
        mix-blend-mode: darken;
    }
}

tr.mat-header-row{height: fit-content !important;}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{padding-left: 6px !important;}
th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{padding-right: 6px !important;}
.mat-sort-header-arrow{top: -2px; color: var(--primary);}

.mat-paginator-range-actions button{
    border-radius: 8px;
    border: 1px solid #0002;

    &:not(:last-child){margin-right: 8px;}

    .mat-button-wrapper{
        position: relative;top: -2px;
    }
    

    &:not(.mat-button-disabled){color: var(--primary);}
}

tr.mat-row, tr.mat-footer-row{height: auto !important;}


.hide-selected {
    .ng-select-container{
        .ng-value-container {
            .ng-value {
              display: none;
            }

        }
        .ng-placeholder{display: block;}
    }
}

span.text-danger{color: rgb(240, 18, 18) !important; font-weight: bold;}

.disabled{
    opacity: 0.4;
    pointer-events: none;
}